import { Navigate } from "react-router-dom";
import { useAuth } from "../../zustand-store/auth-store";

export const ProtectedRoutes = ({ children }) => {
  const { userDetail } = useAuth();

  if (userDetail === null) {
    return <Navigate to="/" />;
  } else if (userDetail !== null) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};
