import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routesData } from "../routes";
import { Suspense, useMemo } from "react";
import { InitialLoadUi } from "../components/initial-load-ui";
import { ToastMessage } from "../components/toast-messages";
import { useToastMessages } from "../zustand-store/toastMessages-store";
import { useAuth } from "../zustand-store/auth-store";
import { filterByRole } from "../utils/filterByRoleUtil";

const Shell = () => {
  const { message, status } = useToastMessages();
  const { userDetail } = useAuth();

  const useAuthRoutes = useMemo(() => {
    if (userDetail?.role?.length) {
      return filterByRole(routesData, userDetail.role);
    }
    return routesData;
  }, [userDetail]);

  return (
    <>
      <ToastMessage message={message} status={status} />
      <Suspense fallback={<InitialLoadUi />}>
        <RouterProvider router={createBrowserRouter(useAuthRoutes)} />
      </Suspense>
    </>
  );
};

export default Shell;
