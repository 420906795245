import { useEffect } from "react";
import { clearMessage } from "../../zustand-store/toastMessages-store/actions";
import { CloseIcon, ErrorIcon, SuccessIcon } from "../svg";
import styles from "./style.module.scss";

export const ToastMessage = ({ message, status }) => {
  const handleToast = () => {
    clearMessage();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      clearMessage();
    }, 4000);
    return () => {
      clearTimeout(timeout);
    };
  }, [message]);
  return (
    <>
      {message?.length && (
        <section className={styles.notificationShow}>
          <div className={styles.notification}>
            <div className={styles.notificationHeader}>
              <div className={styles.title}>
                <span className={styles.image}>
                  {status ? <SuccessIcon /> : <ErrorIcon />}
                </span>
                <p className={styles.message}>{message}</p>
              </div>
              <CloseIcon
                width="25"
                height="25"
                fillColor={status ? "#02BF90" : "#B11313"}
                handleClick={() => handleToast()}
                customClass={styles.closeIcon}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};
