import { lazy } from "react";

export const LazyLogin = lazy(() => import("../pages/login-signup/login"));
export const LazySignUp = lazy(() => import("../pages/login-signup/sign-up"));
export const LazyEmailVerification = lazy(() =>
  import("../pages/login-signup/email-verification")
);
export const LazyResetPassword = lazy(() =>
  import("../pages/login-signup/reset-password")
);
export const LazyPasswordSent = lazy(() =>
  import("../pages/login-signup/password-sent")
);
export const LazySetPassword = lazy(() =>
  import("../pages/login-signup/set-password")
);
export const LazyPhoneVerification = lazy(() =>
  import("../pages/login-signup/phone-verification")
);
export const LazyMainLayout = lazy(() => import("../pages/main-layout"));
export const LazyChapters = lazy(() => import("../pages/chapters"));
export const LazyChaptersOutlet = lazy(() =>
  import("../pages/chapters/chaptersOutlet")
);
export const LazyPasswordChanged = lazy(() =>
  import("../pages/login-signup/password-changed")
);
export const LazyTestimonials = lazy(() => import("../pages/testimonial"));
export const LazySubscriptions = lazy(() => import("../pages/subscription"));
export const LazyUserManagement = lazy(() =>
  import("../pages/user-management")
);
export const LazyReminder = lazy(() => import("../pages/reminder"));
export const LazyLegals = lazy(() => import("../pages/legals"));
export const LazyChats = lazy(() => import("../pages/chat"));
export const LazyProfile = lazy(() => import("../pages/profile"));
export const LazyLessons = lazy(() => import("../pages/lessons"));
