import logo from "../../assests/images/pronounci-logo.png";

export const InitialLoadUi = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: `translate(${-50}%, ${-50}%)`,
        width: "100px",
      }}
    >
      <img src={logo} alt="logo" />
    </div>
  );
};
