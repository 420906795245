export const filterByRole = (data, loggedInRole) => {
  let tempArray = [];
  tempArray = data
    ?.filter((item) => {
      return item?.role?.includes(loggedInRole);
    })
    .map((item) => {
      if (item?.children) {
        let childArr = filterByRole(item?.children, loggedInRole);
        return {
          ...item,
          children: childArr,
        };
      } else {
        return item;
      }
    });
  return tempArray ?? [];
};

// export const filterByRole = (routesData, userRole) => {
//   return routesData
//     .map((route) => {
//       const isRouteAllowed = route.role.includes(userRole);

//       const filteredChildren = route.children
//         ? route.children.filter((child) => child.role.includes(userRole))
//         : [];

//       if (isRouteAllowed) {
//         return {
//           ...route,
//           children: filteredChildren.length > 0 ? filteredChildren : undefined,
//         };
//       }

//       return null;
//     })
//     .filter(Boolean);
// };
